import { UserOfUnit } from "./profileService";

export function fnDexcomG7MailTemplate(d: FormData): string {
  return `${introduction(d)}

Dexcom användare: ${d.get("dexcom-username")}

LOT: ${d.get("sensor-lot")}
SN: ${d.get("sensor-sn")}
Startades: ${d.get("sensor-start-date")}
Slutade fungera: ${d.get("sensor-end-date")}
Placering: ${d.get("sensor-placement")}

${greetings(d)}
`;
}

export function fnOmniPodDashMailTemplate(d: FormData): string {
  return `${introduction(d)}

Pumpens sekvensnummer: ${d.get("pump-sn")}
Pumpens LOT-nr: ${d.get("pump-lot")}
PDM s/n: ${d.get("pdm-sn")}
Startades: ${d.get("pump-start-date")}
Slutade fungera: ${d.get("pump-end-date")}
Placering: ${d.get("pump-placement")}

${greetings(d)}
`;
}

export function fnFreestyleLibreMailTemplate(d: FormData): string {
  return `${introduction(d)}

${d.get("libre-assistance")}

Vilket problem upplevde du med sensorn?
${d.get("libre-problem")}

Finns din sensor tillgänglig för retur till Abbott Diabetes Care?
${d.get("libre-sensor-available")}

Sensor S/N:
${d.get("sensor-sn")}

Sensorn slutade fungera:
${d.get("sensor-end-date")}

Vilken enhet använder du för att skanna sensorn?
${d.get("freestyle-sensor-reader")}

Var ifrån har du fått denna sensorn?
${d.get("freestyle-sensor-source")}

${greetings(d)}
`;
}

function introduction(d: FormData): string {
  return `Hej

Jag vill reklamera en ${d.get("unit-name")}.
${user(d)}
Problembeskrivning:
${d.get("problem-description")}`;
}

function user(d: FormData): string {
  let s = "";
  if (d.get("user-of-unit") === UserOfUnit.Other) {
    s = `
Användare av enheten är:
${d.get("user-of-unit-name")}
`;
  }
  return s;
}

function greetings(d: FormData): string {
  return `Ersättning kan skickas till:
${d.get("sender-name")}
${d.get("sender-street")}
${d.get("sender-postal-code")} ${d.get("sender-city")}

Mobiltelefon: ${d.get("sender-phone")}

Mvh
${d.get("sender-name")}`;
}
